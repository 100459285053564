<template>
  <div>
    <div v-if="permissions['operacoes.index'].permissao">
      <div
        id="filtro"
      >
        <b-modal
          id="updateStatus"
          no-close-on-backdrop
          title=" Motivo Pendência"
          ok-title="Alterar"
          ok-only
          cancel-variant="danger"
          @ok="updateLot"
          @show="limparUpdateLot"
        >
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              Motivo Pendência:
              <v-select
                v-model="novoStatus"
                label="title"
                :options="mtPend.options"
              />
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              Descrição:
              <b-form-textarea
                v-model="description"
              />
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="modal-filtro"
          title="Filtros"
          size="lg"
          cancel-title="Cancelar"
          cancel-variant="danger"
          ok-title="Filtrar"
          @ok="setFilter"
        >
          <b-row>
            <b-col md="12">
              <label for="credorName">Credor</label>
              <v-select
                id="credorName"
                v-model="credor.selected"
                multiple
                label="title"
                :options="credorOptions"
                :close-on-select="false"
              />
            </b-col>
            <b-col
              md="6"
              class="mt-2"
            >
              <label>Período Vencimento</label>
              <b-row>
                <b-col cols="6">
                  <b-form-input
                    id="vencimento-inicio"
                    v-model="vencimento.inicio"
                    locale="pt"
                    type="date"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    id="vencimento-final"
                    v-model="vencimento.final"
                    locale="pt"
                    type="date"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col
              lg="6"
              class="mt-2"
            >
              <b-row align-h="between">
                <b-col>
                  <label>Status</label>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="statusOp.exceto"
                    value="1"
                    unchecked-value="0"
                    inline
                    class="custom-control-primary"
                  >
                    Exceto
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <v-select
                v-model="statusOp.selected"
                label="title"
                :options="statusOp.options"
              />
            </b-col>
            <b-col
              lg="6"
              class="mt-2 d-none"
            >
              <b-row class="justify-content-between">
                <b-col>
                  <label>Evento</label>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="eventos.exceto"
                    value="1"
                    inline
                    class="custom-control-primary"
                  >
                    Exceto
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <v-select
                v-model="eventos.selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="title"
                :options="eventos.options"
              />
            </b-col>
            <b-col
              lg="6"
              class="mt-2"
            >
              <b-row class="justify-content-between">
                <b-col>
                  <label>Motivo Pendência</label>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="mtPend.exceto"
                    value="1"
                    inline
                    unchecked-value="0"
                    class="custom-control-primary"
                  >
                    Exceto
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <v-select
                v-model="mtPend.selected"
                label="title"
                multiple
                :options="mtPend.options"
              />
            </b-col>
            <b-col
              lg="6"
              class="mt-2"
            >
              <b-row align-h="between">
                <b-col>
                  <label>Classificação Negativação</label>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="classNeg.exceto"
                    value="1"
                    unchecked-value="0"
                    inline
                    class="custom-control-primary"
                  >
                    Exceto
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <v-select
                v-model="classNeg.selected"
                label="title"
                :options="classNeg.options"
              />
            </b-col>
            <b-col
              lg="6"
              class="mt-2"
            >
              <b-row align-h="between">
                <b-col>
                  <label>Classificação Cliente</label>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="classCliente.exceto"
                    value="1"
                    inline
                    unchecked-value="0"
                    class="custom-control-primary"
                  >
                    Exceto
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <v-select
                v-model="classCliente.selected"
                label="title"
                multiple
                :options="classCliente.options"
              />
            </b-col>
            <b-col
              md="5"
              class="mt-2"
            >
              <label>Período de contato</label>
              <b-row>
                <b-col md="6">
                  <b-form-input
                    v-model="contato_inicio"
                    locale="pt"
                    type="date"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-input
                    v-model="contato_final"
                    local="pt"
                    type="date"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="3"
              class="mt-2"
            >
              <label>Valor R$</label>
              <b-row>
                <b-col md="6">
                  <b-form-input
                    v-model="valor.inicial"
                    type="number"
                    size="sm"
                    placeholder="Inicial"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-input
                    v-model="valor.final"
                    type="number"
                    size="sm"
                    placeholder="Final"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="4"
              class="mt-2"
            >
              <b-form-radio-group
                v-model="valor.select"
                name="radio-validation"
                :options="valor.options"
                value-field="value"
                text-field="text"
                disabled-field="notEnabled"
              />
            </b-col>
          </b-row>
        </b-modal>
      </div>

      <!-- <Filtro
        ref="filtro"
        :operacoes="true"
        title="Filtrar Operações"
        :set-data="setOperacoes"
      /> -->

      <!-- <OperacoesTable
        v-if="loadOperacoes"
        :operacoes="operacoes"
        :update-table="setFilter"
      /> -->
      <b-row>
        <b-col cols="12">
          <b-row
            class="mb-2"
            align-h="between"
          >
            <b-col cols="auto">
              <b-row align-h="start">
                <b-col cols="auto">
                  <b-button
                    v-b-modal.modal-filtro
                    variant="warning"
                  >
                    Filtro
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="auto">
              <b-row
                align-h="end"
              >
                <b-col
                  cols="auto"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    variant="outline-warning"
                    @click="exportFile"
                  >
                    <feather-icon
                      icon="FileTextIcon"
                    />
                    Exportar excel
                  </b-button>
                </b-col>
                <b-col
                  cols="auto"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    variant="outline-warning"
                    @click="$refs.updateStatus.show()"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                    Alterar Status
                  </b-button>
                </b-col>
                <b-modal
                  ref="updateStatus"
                  ok-only
                  ok-title="Alterar"
                  @show="limparModalStatus"
                  @ok="updateStatusOperacoes()"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      Status:
                      <v-select
                        v-model="updateStatusOp.status_operacao_id"
                        label="title"
                        :options="statusOp.options"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      Descrição:
                      <b-form-textarea
                        v-model="updateStatusOp.description"
                      />
                    </b-col>
                  </b-row>
                </b-modal>
                <b-col
                  cols="auto"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    v-if="permissions['operacoes.store'].permissao"
                    v-b-modal.updateStatus
                    variant="outline-warning"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                    Alterar Pendência
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col vols="12">
          <b-card>
            <!-- <div v-if="load">
              <b-row align-h="center">
                <b-col
                  cols="auto"
                  class="d-flex"
                >
                  <b-spinner style="width: 10rem; height: 10rem;" />
                </b-col>
              </b-row>
            </div> -->
            <b-overlay
              :show="load"
              variant="transparent"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <b-row align-h="between">
                    <b-col cols="1">
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="[10, 15, 20]"
                      />
                    </b-col>
                    <b-col
                      lg="8"
                      class="d-flex justify-content-end"
                    >
                      <b-form-input
                        v-model="filter"
                        type="search"
                        size="sm"
                        placeholder="Pesquisar"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <b-table
                    ref="selectableTable"
                    small
                    striped
                    responsive
                    :fields="fields"
                    :items="operacoes1"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    class="text-center"
                    @row-clicked="rowClick"
                    @filtered="onFiltered"
                  >
                    <template
                      v-if="permissions['operacoes.store'].permissao"
                      #head(check)
                    >
                      <b-form-checkbox
                        v-model="selectAll"
                      />
                    </template>
                    <template
                      v-if="permissions['operacoes.store'].permissao"
                      #cell(check)="row"
                    >
                      <b-form-checkbox
                        v-model="selected"
                        :value="row.item.id"
                      />
                    </template>
                    <template #cell(status)="row">
                      <b-badge
                        pill
                        :style="{background: row.item.color, color: '#fff',}"
                      >
                        {{ row.item.sigla }}
                      </b-badge>
                    </template>
                    <template #cell(valor_nominal)="row">
                      {{ row.item.valor_nominal ? valorBr(parseFloat(row.item.valor_nominal), true):'' }}
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalRows"
                    align="center"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        id="modal"
        ref="modal"
        hide-footer
        centered
        size="lg"
        title="Detalhes da Operação"
      >
        <InfoCard
          :id-op="id"
        />
      </b-modal>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BTable,
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BPagination,
  BFormSelect,
  BOverlay,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import InfoCard from './cards/OperacoesInfo.vue'

const ExcelJS = require('exceljs')
// import OperacoesTable from '@/views/backoffice/operacao/cards/OperacoesTable.vue'
// import Filtro from '@/components/filtros/OperacoesFiltro.vue'

export default {
  components: {
    // OperacoesTable,
    // Filtro,
    InfoCard,
    BCard,
    BBadge,
    BTable,
    BButton,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BPagination,
    BFormSelect,
    BOverlay,
    BFormTextarea,
    notAuthorized,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      permissions: null,
      updateStatusOp: {
        status_operacao_id: null,
        ops_ids: null,
        motivo_id: 79,
        description: null,
      },
      load: true,
      selectAll: false,
      id: null,
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      filter: '',
      selected: [],
      novoStatus: null,
      description: null,
      fields: [
        'check',
        'status',
        { key: 'numero_operacao', label: 'operação', class: 'text-left' },
        { key: 'pendencia', label: 'pendência', class: 'text-left' },
        // 'id',
        { key: 'cliente', class: 'text-left' },
        { key: 'credor', class: 'text-left' },
        { key: 'data_vencimento', label: 'dt vencimento' },
        { key: 'data_processamento', label: 'dt processamento' },
        { key: 'valor_nominal', label: 'vl nominal', class: 'text-right' },
        { key: 'valor_atualizado', label: 'vl atualizado', class: 'text-right' },
        'desconto',
      ],
      operacoes: [],
      operacoes1: [],
      status_operacoes: {},
      clientesInfo: [],
      loadOperacoes: false,
      dir: null,
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      statusOp: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      eventos: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      mtPend: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classNeg: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classCliente: {
        options: [],
        ids: [],
        selected: '',
        exceto: '0',
      },
      valor: {
        options: [
          { text: 'Por Operação', value: 1 },
          { text: 'Ticket Médio Cliente', value: 2, notEnabled: true },
          { text: 'Ticket Médio Operação', value: 3, notEnabled: true },
        ],
        select: null,
        inicial: '',
        final: '',
        avaible: false,
      },
      contato_inicio: '',
      contato_final: '',
      data: [],
    }
  },
  computed: {
    credorOptions() {
      return this.credor.credOption.sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
  },
  watch: {
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).operacoes
    this.addColor()
    await axios.get('api/v1/operacoes', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.load = true
      this.operacoes = []
      // eslint-disable-next-line array-callback-return
      res.data.dados.operacoes.data.map(async dt => {
        await this.operacoes.push({
          id: dt.id,
          sigla: dt.status_operacao.sigla,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente.nome,
          credor: dt.remessa.credor.nome,
          data_vencimento: this.dataHora(dt.data_vencimento, true),
          data_processamento: this.dataHora(dt.data_processamento, true),
          valor_nominal: dt.valor_nominal,
          valor_atualizado: this.valorBr(parseFloat(dt.valor_atualizado), true),
          descricao: dt.descricao,
          desconto: dt.desconto,
          pendencia: dt.motivo_pendencia ? dt.motivo_pendencia.nome : dt.motivo_pendencia,
          status: dt.status_operacao.nome,
        })
        this.totalRows = this.operacoes.length
        this.clientesInfo.push({
          cliente_id: dt.cliente.id,
          // cliente_nome: dt.cliente.nome,
          // cliente_cpf_cnpj: dt.cliente.cpf_cnpj,
          // cliente_endereco: dt.cliente.endereco,
          // cliente_bairro: dt.cliente.bairro,
          // cliente_cep: dt.cliente.cep,
        })
      })
      this.load = false
      this.changeColor()
      this.limparSelecao()
    })
    // CREDORES
    await axios.get('api/v1/credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const objeto = res.data.dados
        // eslint-disable-next-line array-callback-return
        objeto.map(dt => {
          this.credor.credOption.push({ title: dt.nome, id: dt.id })
        })
      })

    // STATUS OPERAÇÃO
    await axios.get('api/v1/info/statusoperacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.statusOp.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // EVENTO
    await axios.get('api/v1/motivo_evento', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.eventos.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // MOTIVO PENDENCIA
    await axios.get('api/v1/info/motivopendencia', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.mtPend.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO NEGATIVAÇÃO
    await axios.get('api/v1/info/classificacaonegativacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classNeg.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO CLIENTE
    await axios.get('api/v1/info/classificacaocliente', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classCliente.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })
  },

  mounted() {
    // this.$bvModal.show('modal-filtro')
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async updateStatusOperacoes() {
      const body = {
        status_operacao_id: this.updateStatusOp.status_operacao_id.id,
        ops_ids: this.selected,
        motivoId: 12,
        description: this.updateStatusOp.description,
      }

      await axios.put('api/v1/operacoes/update/lote/status/operacao', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.setFilter()
          this.$swal({
            title: 'Alteração execultada!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    rowClick(item) {
      this.id = null
      this.id = item.id
      this.$bvModal.show('modal')
    },
    async addColor() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.status_operacoes = {}
        const colors = {
          C: '#B22222',
          L: '#66CDAA',
          D: '#696969',
          N: '#836FFF',
          A: '',
        }
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(dt => {
          this.status_operacoes[dt.sigla] = {
            sigla: dt.sigla,
            color: colors[dt.sigla],
          }
        })
      })
      // this.setFilter()
    },
    limparModalStatus() {
      this.updateStatusOp.status_operacao_id = null
      this.updateStatusOp.description = null
    },

    limparUpdateLot() {
      this.novoStatus = null
      this.description = null
    },

    async updateLot() {
      const body = {
        motivo_pendencia_id: this.novoStatus.id,
        ops_ids: this.selected,
        description: this.description,
        motivoId: 27,
      }
      await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.selected = []
        this.$swal({
          title: 'SUCESSO!',
          text: 'Alteração executada com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      }).catch(() => {
        this.selected = []
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
      this.setFilter()
    },
    onRowSelected(item) {
      this.selected = item
    },
    limparSelecao() {
      this.selected = []
    },
    selecionarTodos() {
      this.selected = []

      this.operacoes1.map(dt => {
        this.selected.push(dt.id)
      })
    },

    async setOperacoes(data) {
      this.loadOperacoes = false
      await (this.operacoes = data)
      this.loadOperacoes = true
    },

    async setFilter() {
      this.load = true
      this.loadOperacoes = false
      let body = {}
      if (this.data_inicial && this.data_final) {
        body = {
          // pag: 1,
          // item_pag: 15,
          filtros: {
            periodo_contato: {
              data_inicial: '',
              data_final: '',
              exceto: 0,
            },
          },
        }
      } else {
        body = {
          // pag: 1,
          // item_pag: 15,
          filtros: {},
        }
      }
      // VALIDACAO CREDOR
      if (this.credor.selected.length) {
        this.credor.ids = []
        // eslint-disable-next-line array-callback-return
        await this.credor.selected.map(item => {
          this.credor.ids.push(item.id)
        })
        body.filtros.credores = this.credor.ids
      }

      // VALIDACAO VENCIMENTO
      if (this.vencimento.inicio && this.vencimento.final) {
        body.filtros.vencimento = {
          data_inicial: this.vencimento.inicio,
          data_final: this.vencimento.final,
        }
      }

      // VALIDACAO STATUS
      if (this.statusOp.selected) {
        body.filtros.status_operacao = {
          valor: this.statusOp.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.statusOp.exceto),
        }
      }

      // VALIDACAO EVENTOS
      if (this.eventos.selected) {
        body.filtros.eventos = {
          valor: this.eventos.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.eventos.exceto),
        }
      }

      // VALIDACAO PENDENCIA
      if (this.mtPend.selected.length) {
        this.mtPend.ids = []
        // eslint-disable-next-line array-callback-return
        await this.mtPend.selected.map(item => {
          this.mtPend.ids.push(item.id)
        })
        body.filtros.motivo_pendencia = {
          valor: this.mtPend.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.mtPend.exceto),
        }
      }

      // VALIDACAO VALOR
      // eslint-disable-next-line no-mixed-operators
      if (this.valor.select && this.valor.inicial !== 0 && this.valor.final !== 0 && this.valor.inicial !== null && this.valor.final !== null) {
        body.filtros.valor = {
          tipo: this.valor.select,
          valor_inicial: parseFloat(this.valor.inicial),
          valor_final: parseFloat(this.valor.final),
        }
      }

      // PERIODO CONTATO
      if (this.contato_inicio && this.contato_final) {
        body.filtros.periodo_contato = {
          data_inicial: `${this.contato_inicio} 00:00:00`,
          data_final: `${this.contato_final} 23:59:59`,
          exceto: 0,
        }
      }

      // VALIDCAO CLASSIFICACAO NEGATIVACAO
      if (this.classNeg.selected) {
        body.filtros.classificacao_negativacao = {
          valor: this.classNeg.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classNeg.exceto),
        }
      }

      // VALIDACAO CLASSIFICAÇÃO CLIENTE
      if (this.classCliente.selected.length) {
        this.classCliente.ids = []
        // eslint-disable-next-line array-callback-return
        await this.classCliente.selected.map(item => {
          this.classCliente.ids.push(item.id)
        })
        body.filtros.classificacao_cliente = {
          valor: this.classCliente.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classCliente.exceto),
        }
      }

      if (Object.keys(body.filtros).length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/operacoes/filtro', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          // this.load = true
          this.operacoes = []
          // eslint-disable-next-line array-callback-return
          res.data.dados.operacoes.map(dt => {
            this.operacoes.push({
              id: dt.id,
              sigla: dt.status_operacao.sigla,
              numero_operacao: dt.operacao,
              cliente: dt.cliente,
              credor: dt.credor,
              data_vencimento: this.dataHora(dt.data_vencimento, true),
              data_processamento: this.dataHora(dt.data_processamento, true),
              valor_nominal: dt.valor_nominal,
              valor_atualizado: this.valorBr(parseFloat(dt.valor_atualizado), true),
              descricao: dt.descricao,
              desconto: dt.desconto,
              pendencia: dt.motivo_pendencia ? dt.motivo_pendencia.nome : dt.motivo_pendencia,
              status: dt.status_operacao.nome,
            })
            this.totalRows = this.operacoes.length
            // this.clientesInfo.push({
            //   cliente_id: dt.cliente_id,
            //   cliente_nome: dt.cliente_nome,
            //   cliente_cpf_cnpj: dt.cliente_cpf_cnpj,
            //   cliente_endereco: dt.cliente_endereco,
            //   cliente_bairro: dt.cliente_bairro,
            //   cliente_cep: dt.cliente_cep,
            // })
          })
          this.load = false
          this.changeColor()
        })
      }
    },
    async changeColor() {
      this.operacoes1 = []
      await this.operacoes.map(dt => {
        const { sigla, color } = this.status_operacoes[dt.sigla]
        this.operacoes1.push({
          id: dt.id,
          sigla,
          color,
          pendencia: dt.pendencia,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente,
          credor: dt.credor,
          data_vencimento: dt.data_vencimento,
          data_processamento: dt.data_processamento,
          valor_nominal: dt.valor_nominal,
          valor_atualizado: dt.valor_atualizado,
          descricao: dt.descricao,
          desconto: dt.desconto,
          status: dt.status,
        })
      })
    },

    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.operacoes1

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'CREDOR', key: 'credor' },
        { header: 'CLIENTE', key: 'cliente' },
        { header: 'NÚMERO DA OPERAÇÃO', key: 'numero_operacao' },
        { header: 'DATA PROCESSAMENTO', key: 'data_processamento' },
        { header: 'DATA VENCIMENTO', key: 'data_vencimento' },
        { header: 'DESCRIÇÃO', key: 'descricao' },
        { header: 'DESCONTO', key: 'desconto' },
        { header: 'PÊNDENCIA', key: 'pendencia' },
        { header: 'SIGLA', key: 'sigla' },
        { header: 'STATUS', key: 'status' },
        { header: 'VALOR ATUALIZADO', key: 'valor_atualizado' },
        { header: 'VALOR NOMINAL', key: 'valor_nominal' },
      ]
      const wk1Columns = [
        'valor_nominal',
      ]
      const totais = {
        wk1: {},
      }
      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.credor,
          item.cliente,
          item.numero_operacao,
          item.data_processamento,
          item.data_vencimento,
          item.descricao,
          item.desconto,
          item.pendencia === null ? '' : item.pendencia,
          item.sigla,
          item.status,
          item.valor_atualizado,
          item.valor_nominal,
        ])
        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_nominal: totais.wk1.valor_nominal,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Operação', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
